<template>
    <div :class="[flag? '': 'content-box']"> 
       <div :class="[flag? '': 'container']">
            <div class="content">
            <div class="detailTitle" style="font-size:18px;margin:30px 0 15px">
                 {{data.name}} 投保记录
            <div class="save"><a-button type="primary" @click="showInsure">新增投保</a-button></div>
            </div>
            <div class="listBox" v-for="(item,index) in insureList" :key="index" >
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">类型：</label>{{item.insurance}}</div>
                        <div><label for="">金额：</label>{{item.money}}</div>

                    </div>
                    <div class="flex">
                        <div><label for="">生效时间：</label>{{item.createtime}}</div>
                        <div><label for="">到期时间：</label>{{item.endtime}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">投保时间：</label>{{item.time}}</div>
                        <div><label for="">操作人：</label>{{item.sname}}</div>
                    </div>
                </div>
                 <div class="btn_con">
                    <!-- <a-button type="primary" size="small" ghost @click="showEditRecord(item)">编辑</a-button> -->
                    <a-popconfirm title="确定要删除吗?"  @confirm="() => delInsure(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
             <div style="color:#999;height:100px;line-height:60px" v-if="insureList.length==0">
                暂无数据
            </div>
            <div class="detailTitle" style="font-size:18px;margin:30px 0 15px">
                工服发放记录
            <div class="save"><a-button type="primary" @click="showClothes">工服发放</a-button></div>
            </div>
            <div class="listBox" v-for="(item,index) in clothesList" :key="index">
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">发放编号：</label>{{item.id}}</div>
                        <div><label for="">工服类型：</label>{{item.clotype}}</div>
                        <div><label for="">工服金额：</label>{{item.money}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">发放时间：</label>{{item.time}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">补助开始时间：</label>{{item.createtime}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">补助结束时间：</label>{{item.endtime}}</div>
                    </div>
                </div>
                 <div class="btn_con">
                    <!-- <a-button type="primary" size="small" ghost @click="showEditRecord(item)">编辑</a-button> -->
                    <a-popconfirm title="确定要删除吗?"  @confirm="() => delClothes(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <div style="color:#999;height:100px;line-height:60px" v-if="clothesList.length==0">
                暂无数据
            </div>
            <div style="font-size:18px;margin:30px 0 15px">
                工服补助发放记录
            <!-- <div class="save"><a-button type="primary" @click="showClothes">工服发放</a-button></div> -->
            </div>
             <div class="listBox" v-for="(item,index) in subsidyList" :key="index">
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">工服补助月数：</label>{{item.clotype}}</div>
                        <div><label for="">工服金额：</label>{{item.money}}</div>
                        <div><label for="">补助金额：</label>{{item.subsidy_money}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">补助发放时间：</label>{{item.subsidy_time}}</div>
                    </div>
                </div>
            </div>
            <div style="color:#999;height:100px;line-height:60px" v-if="subsidyList.length==0">
                暂无数据
            </div>
            <a-modal v-model="insureModal" :centered="true" title="投保"  @ok="setInsure">
                <div class="addBox">
                    <div class="add_title">保险类型</div>
                    <a-select v-model="insData.insurance" style="width:100%" class="ant-select-lg">
                        <a-select-option :value="1">工伤保险</a-select-option>
                        <a-select-option :value="2">商业保险</a-select-option>
                        <a-select-option :value="3">社会保险</a-select-option>
                    </a-select>
                    <div class="add_title">投保金额</div>
                    <a-input v-model="insData.money" size="large" type="number" placeholder="请输入投保金额"/>
                    <div class="add_title">保险生效时间</div>
                    <a-date-picker size="large" :inputReadOnly="true" @change="onInsCTime" />
                    <div class="add_title">保险到期时间</div>
                     <a-date-picker size="large" :inputReadOnly="true" @change="onInsETime" />
                </div>
            </a-modal>
             <a-modal v-model="clothesModal" :centered="true" title="发放工服"  @ok="setClothes">
                <div class="addBox" style="padding-bottom:45px">
                    <div class="add_title">工服类型</div>
                    <a-select v-model="onClotype" style="width:100%;" class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in clotypeList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </a-modal>
            <!-- <div class="save"><a-button type="primary" @click="submit">保存</a-button></div> -->
            </div>
        </div>
        </div>
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml, getDateTime} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "name": "",
                "number": "",
                "sex": "",
                "age": "",
                "code": "",
                "tel": "",
                "status": "",
                "post": "",
                "card": "",
                "contacts": "",
                "contactstel": "",
                "uid": "",
                "remarks": "",
                "base": "",
                "cleaning":'',
                "green":'',
                "createtime":'',
            },
            createtime:'', //入职时间
            selSkillid:'',  //技能选项
            operatList:'', //工作点列表
            insureModal:false, //新增投保窗口
            insData:{
                 "workerid": '',
                "insurance": '',
                "money": "",
                "uid": "",
                "time": "",
                "createtime": "",
                "endtime": "",
            },
            insureList:'', //投保信息
            insCTime:'', //保险生效时间
            insETime:'', //保险到期时间
            clothesList:'', //工装记录
            clotypeList:'', //工服类型列表
            onClotype:'', //选中工服类型
            clothesModal:false, //添加工服窗口
            subsidyList:'',  //补助记录
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        this.getStaff();
        // this.getSkillid();
        this.getOperat();
        this.getInsure();
        this.getClotype();
        this.getClothes();
        this.getSubsidy();
         if(this.id){
                this.getDetail();
            }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        submit(){
            let url='/jieb/Worker/workeradd';
            if(this.id){
                url='/jieb/Worker/workeredit';
                this.data.id=this.id;
            }
             requestXml(url,"POST",(status,res) => {
                console.log(status,res);
                // if(status!='error'){
                //     this.$message.success('成功');
                // }
                // if(res){
                //     this.$message.info(res.msg);
                // }
            },this.data)
        },
        // onPickTime(date, dateString){
        //     console.log(date);
        //     var time=new Date(dateString).getTime();
        //     this.data.createtime= Math.round(time/1000); 
        // },
        // 
        //获取工服补助记录
        getSubsidy(){
             requestXml("/jieb/Worker/workerdeta","POST",(res) => {
              this.subsidyList=res.list.subsidy;
            },{id:this.id})
        },
         showClothes(){
            this.clothesModal=true;
        },
        //获取工服发放记录
        getClothes(){
             requestXml("/jieb/Worker/clothesshow","POST",(res) => {
              this.clothesList=res.list;
            },{id:this.id})
        },
        setClothes(){
            if(!this.onClotype){
                this.$message.info('请选择要发放的工服');
                return false;
            }
            let time=Math.round(new Date() / 1000);
              requestXml("/jieb/Worker/clothesadd","POST",(res) => {
                  console.log(res);
                this.onClotype='';
                this.clothesModal=false;
                this.getClothes();
            },{time:time,clotypeid:this.onClotype,workerid:this.id})
        },
        delClothes(id){
            requestXml("/jieb/Worker/clothesdel","POST",(res) => {
                console.log(res);
                this.getClothes();
            },{id:id})
        },
        // 获取工服分类
        getClotype(){
            requestXml("/jieb/Worker/clotypeshow","POST",(res) => {
                this.clotypeList = res.list;
            },{"page":{"curpage":1,"pagesize":999}})
        },

        showInsure(){
            this.insureModal=true;
        },
       // 选择开始时间
        onPickCTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.createtime= Math.round(time/1000); 
        },
        // 投保 开始时间
        onInsCTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.insData.createtime= Math.round(time/1000); 
        },
        // 投保 结束
        onInsETime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.insData.endtime= Math.round(time/1000); 
        },
        setInsure(){
            let data=this.insData;
            console.log(data);
            if(!data.insurance||!data.money||!data.createtime||!data.endtime){
                this.$message.info('请确定信息填写完整');
                return false;
            }
            let time=Math.round(new Date() / 1000);
            requestXml("/jieb/Worker/insureadd","POST",(res) => {
            console.log(res);
            if(res!='error'){
                this.insureModal=false;
            }
            this.getInsure();
            },{workerid:this.id,insurance:data.insurance,money:data.money,createtime:data.createtime,endtime:data.endtime,time:time})
        },
        //获取投保信息
        getInsure(){
             requestXml("/jieb/Worker/insureshow","POST",(res) => {
                let insType=['','工伤保险','商业保险','社会保险'];
                for(let i=0; i<res.list.length;i++){
                    res.list[i].createtime=res.list[i].createtime?res.list[i].createtime.split(' ')[0]:'';
                    res.list[i].endtime=res.list[i].endtime?res.list[i].endtime.split(' ')[0]:'';
                    res.list[i].insurance=insType[Number(res.list[i].insurance)];
                }
                this.insureList = res.list;
                console.log(res);
            },{id:this.id})
        },
        delInsure(id){
            requestXml("/jieb/Worker/insuredel","POST",(res) => {
                console.log(res);
                this.getInsure();
            },{id:id})
        },
        
        // 获取工作点
        getOperat(){
             requestXml("/jieb/Operating/operatshow","POST",(res) => {
                this.operatList = res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillid=res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));
               
                // console.log(this.skillid);
            },{"page":{"curpage":1,"pagesize":999999}})
        },
          getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Worker/workeredit","GET",(res) => {
                this.createtime=getDateTime(res.createtime);
                this.data = Object.assign({}, res);
            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .listBox{
        margin: 0 15px;
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid #ededed;
        border-radius: 5px;
        justify-content: flex-start;
    }
    .listBox .list_row .flex{padding:3px 0;display: flex;justify-content: space-between;}
    .listBox .btn_con{text-align: left;padding-top:5px;}
    
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .detailTitle{
        display: flex;
        justify-content: center;
    }
    .detailTitle .save .ant-btn{
        margin: 0 2rem;
        height: 25px;
        border-radius: 50px;
        width: auto;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    .add_title{padding: 15px 0;}
    .add_inp{margin-bottom: 60px;}
    .action1 span{text-decoration:underline; cursor: pointer;margin: 0 10px;}
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
